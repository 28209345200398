<template>
    <div class="page-container">
         <!-- 添加弹窗 -->
         <el-dialog title="添加企业" :visible.sync='dialogAddVisible'  width="45%" :close-on-click-modal="false" >
             <el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
                <el-form-item label="部门名称" prop="name">
                    <el-input  v-model="form.name"></el-input>
                </el-form-item>
                <!-- <el-form-item v-model="上级部门" prop=""></el-form-item> -->
                <el-form-item label="部门负责人" prop="department_leader">
                    <el-select v-model="form.department_leader" placeholder="请选择" style="width:100%">
                        <el-option
                        v-for="item in adminList"
                        :key="item.corp_userid"
                        :label="item.corp_name"
                        :value="item.corp_userid">
                        </el-option>
                    </el-select>
                </el-form-item>
               <!--  <el-form-item label="部门英文名" prop="name_en">
                    <el-input  v-model="form.name_en"></el-input>
                </el-form-item> -->
                <el-form-item label="排序" prop="order">
                    <el-input  v-model="form.order"></el-input>
                </el-form-item>
             </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm()">确 定</el-button>
            </div>
         </el-dialog>
    </div>
</template>
<script>
export default {
     inject:['reload'],
   data() {
      return {
        dialogAddVisible:false,
        form:{
            id:'',
            name:'',
            department_leader:'',
            // name_en:'',
            order:''
        },
        rules:{
            name:[
                { required: true,message:'请输入部门名',trigger: 'blur'}
            ],
        },
        adminList:[]
      }
   },
   created(){

   },
   mounted(){
   },
   methods:{
       handleAdd(id){
		   if(id > 0){
			   this.handleEdit(id)
		   }else{
			   this.$api.get('Department/add',null,res=>{
			       if(res.code == 200){
			           this.dialogAddVisible = true
			           this.adminList = res.data
			       }
			   })
		   }
       },
	   handleEdit(id){
	       this.$api.get('Department/edit',{'id':id},res=>{
	           if (res.code == 200) {
	               this.form =  res.data.dept_info
	               this.adminList =  res.data.admin_list
	               this.dialogAddVisible =  true
	           }else{
	               this.$message.error(res.msg)
	           }
	       })
	   },
        confirm(){
             let url = 'Department/add'
             if (this.form.id) {
                 url = 'Department/edit'
             }  
             this.$api.post(url,this.form,res=>{
                 if (res.code  == 200) {
                    this.dialogAddVisible =  false
                    this.$message.success(res.msg)
                    this.reload();   
                    this.$refs["form"].resetFields();
                    
                 }else{
                     this.$message.error(res.msg)
                 }
             }) 
           
        },
     
   }

}
</script>
<style lang="scss">
</style>