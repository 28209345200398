<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
          <div class="search-form">
            <el-form ref="form" :inline="true"  :model="search_key" prop="">
                <el-form-item label="部门名称">
                    <el-input v-model="search_key.name" placeholder="部门名称"></el-input>
                </el-form-item>
                 <el-form-item label="部门负责人">
                    <el-input v-model="search_key.department_leader" placeholder="部门负责人名称"></el-input>
                </el-form-item>
                <el-button type="primary" @click="getData()">查询</el-button>
            </el-form>
         </div>
        <div style="margin-bottom:10px;">
             <el-button size="small" type="" @click="handleAdd()">添加</el-button>
			  <el-button size="small" type="" @click="roleId==1?handleUpdateDialog():handleUpdate()">同步通讯录</el-button>
             <!-- <el-button size="small" type="" @click="handleUpdate()">同步通讯录</el-button> -->
         </div>
         <el-table
             :data="tableData"
            border
			 row-key="id"
			 default-expand-all="true"
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            style="width:100%">
          <!--  <el-table-column
                prop="id"
                label="ID">
            </el-table-column> -->
             <el-table-column
                prop="name"
                label="名称">
            </el-table-column>
           <!-- <el-table-column 
                prop="name_en"
                label="英文名">
            </el-table-column> -->
            <el-table-column
                prop=""
                label="负责人">
				<template slot-scope="scope">
					<el-tag v-for="item in scope.row.dept_leader_data" style="margin-right: 5px;">{{item}}</el-tag>
				</template>
            </el-table-column>
            <el-table-column
                label="操作">
                <template slot-scope="scope">
                    <el-button  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
                    <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
         </el-table>
         <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>     
		 <AddVue ref="childDialog"></AddVue>
		 
		  <el-dialog title="选择同步的企业" :visible.sync='dialogUpdateVisible'  width="30%" :close-on-click-modal="false" >
			   <el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
				   <el-form-item label="企业" prop="gender">
				       <template>
				           <el-radio v-model="form.coid_type" :label="0">当前登录企业</el-radio>
				           <el-radio v-model="form.coid_type" :label="1">指定企业</el-radio>
				       </template>
				   </el-form-item>
				   <el-form-item label="企业" prop="" v-if="form.coid_type == 1">
				       <el-select v-model="form.coid" placeholder="请选择" style="width:100%">
						    <el-option v-for="(item,index) in coList" :key="index" :label="item.title" :value="item.id"></el-option>
				       </el-select>
				   </el-form-item>
			   </el-form>
			   <div slot="footer" class="dialog-footer">
			       <el-button @click="dialogUpdateVisible = false">取 消</el-button>
			       <el-button type="primary" @click="handleUpdate()">同 步</el-button>
			   </div>
		  </el-dialog>
    </div>
</template>
<script>
import store from "../../store"
import AddVue from './Add.vue'	
export default {
    store,
    inject:['reload'],
	components:{
	    AddVue
	},
   data() {
      return {
		
		  tableData:[],
		  currentPage:1,
		  pagesize:10,
		  total:0,
          search_key:{
              name:'',
              department_leader:''
          },
		dialogUpdateVisible:false,		//同步通讯录
		  form:{
			  coid_type:0,
			  coid:''
		  },
		  roleId:localStorage.getItem('role_id'),     //当前登录角色
      }
   },
   created(){
       this.getData()
   },
   mounted(){
   },
   methods:{
       getData(){   //获取企业数据
           this.$api.post('Department/index',{
			   page:this.currentPage,
			   limit:this.pagesize,
			   name:this.search_key.name,
			   department_leader:this.search_key.department_leader
		   },res=>{
               if(res.code == 200){
					this.tableData =  res.data.list??[]
					this.total =  res.data.count
               }else{
				   this.$message.error('数据获取异常！')
			   }
           })
       },
       handleAdd(id=0){
		   this.$refs.childDialog.handleAdd(id)
       },
       handleDel(row){      //删除部门
             this.$alert("确定删除该企业数据吗？", "删除提示", {
                // confirmButtonText:'确定',
                showConfirmButton: true,
                showCancelButton: true,
                callback: action => {
                    if (action == "confirm") {
                        this.$api.delete("Department/del", { "id": row.id }, res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg);
                                this.reload();
                            }
                        },fail=>{
							  this.$message.success(fail.msg);
						});
                    }
                    else if (action == "cancel") {
                        this.reload();
                    }
                }
            });
       },
       handleUpdateDialog(){
		   this.$api.post('Company/getCompanyList',null,res=>{
			   if(res.code == 200){
				   this.coList =  res.data
				   this.dialogUpdateVisible =  true
			   }
		   })
         
       },
	   handleUpdate(){
		   let params = null
		   if(this.form.coid){
			   params = {coid:this.form.coid}
		   }
		   const loading = this.$loading({
		      lock: true,
		      text: '同步数据中；请勿关闭',
		      spinner: 'el-icon-loading',
		      background: 'rgba(0, 0, 0, 0.7)'
		   });
		   this.$api.put('Department/update',params,res=>{
		       if(res.code == 200){
		           this.$message.success(res.msg)
		           this.reload()
		       }
		   },fail=>{
				this.$message.error(fail.msg)
			    loading.close()
		   })
	   },
         getCurrPage(val){   //获取当前页
            this.currentPage =  val
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
        },

   }

}
</script>
<style lang="scss">
</style>